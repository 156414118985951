<template>
  <div class="page page-changepassword">
    <div class="page-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
        <el-form-item label="请输入旧密码" prop="originalPassword">
          <el-input v-model="ruleForm.originalPassword" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="请输入新密码" prop="password">
          <el-input v-model="ruleForm.password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="请确认新密码" prop="repeatPassword">
          <el-input v-model="ruleForm.repeatPassword" placeholder="请确认新密码"></el-input>
        </el-form-item>
        <el-form-item class="text-center">
          <el-row :gutter="20">
            <el-col :span="12"><el-button type="default" @click="router.go(-1)">取消</el-button></el-col>
            <el-col :span="12"><el-button type="primary" @click="submitForm">确定</el-button></el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import publicUrl from '../../assets/js/interface'

export default {
  name: 'page-changepassword',
  data () {
    return {
      loadingCount: 0,
      ruleForm: {
        originalPassword: '',
        password: '',
        repeatPassword: ''
      },
      rules: {
        originalPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        repeatPassword: [
          { required: true, message: '请确认新密码', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    ...mapMutations(['resetData']),
    submitForm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.password !== this.ruleForm.repeatPassword) {
            this.$message.error('两次密码不一致')
            return
          }
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.changePassword, {
            ...this.ruleForm
          }).then(res => {
            if (res.data.code === 0) {
              localStorage.setItem('token', null)
              this.resetData()
              this.$message.success('密码修改成功，请重新登录')
              this.$router.push({name: 'login'})
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-changepassword {
  position: relative;
  .page-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    min-height: 320px;
    .el-button {
      width: 100%;
    }
  }
}
</style>
